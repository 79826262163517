import React, { FC } from 'react';

import { RuleLineProps } from './RuleLine.types';
import { StyledRuleLine } from './RuleLine.styles';
import clsx from 'clsx';

const allowedClasses = [
  'ruler-height-1',
  'ruler-height-3',
  'ruler-height-5',
  'ruler-length-20',
  'ruler-length-40',
  'ruler-length-60',
  'ruler-length-80',
  'ruler-length-100',
  't-horizontal-vertical-rule',
  't-accent-rule',
];

export const RuleLine: FC<RuleLineProps> = (props: RuleLineProps) => {
  const { styleclass, alignment } = props;

  const filteredClassNames = (styleclass || '')
    .split(' ')
    .map(cls => (cls === 't-accent-color' ? 't-accent-rule' : cls))
    .filter(cls => allowedClasses.includes(cls));

  const hasAccentColor = filteredClassNames.includes('t-accent-rule');
  const hasWidthClass = filteredClassNames.some(cls => cls.startsWith('ruler-height-'));
  const hasLengthClass = filteredClassNames.some(cls => cls.startsWith('ruler-length-'));
  const hasTypeClass = filteredClassNames.some(cls => cls === 't-horizontal-vertical-rule' || cls === 't-accent-rule');

  if (!hasAccentColor) {
    filteredClassNames.push('ruler-height-1');
  } else if (!hasWidthClass) {
    filteredClassNames.push('ruler-height-1');
  }

  if (!hasLengthClass) {
    filteredClassNames.push('ruler-length-20');
  }

  if (!hasTypeClass) {
    filteredClassNames.push('t-horizontal-vertical-rule');
  }

  return (
    <StyledRuleLine
      data-testid="ui-library-RuleLine"
      data-component-name="a-ui-library-RuleLine"
      alignment={alignment as string}
    >
      <span className={clsx(filteredClassNames)}></span>
    </StyledRuleLine>
  );
};
