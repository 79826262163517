import React from 'react';
import { EditableComponent } from '@adobe/aem-react-editable-components';

import { BannerText as BannerTextComp } from '@marriott/mi-ui-library';
import clsx from 'clsx';

export const BannerTextConfig = {
  emptyLabel: 'BannerText',
  isEmpty: () => true,
  resourceType: `${process.env['NEXT_PUBLIC_AEM_SITE']}/components/content/bannertext`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BannerText = (props: any) => {
  const model = props.model || {};
  const { styleclass, componentId } = model;
  const filteredClassNames = (styleclass || '')
    .split(' ')
    .filter((cls: string) => cls !== 't-horizontal-vertical-rule' && cls !== 't-accent-color')
    .join(' ');

  return (
    <div
      data-testid="banner-text"
      data-component-name="o-common-static-bannertext"
      id={componentId}
      className={clsx(filteredClassNames)}
    >
      <EditableComponent config={BannerTextConfig} {...props}>
        <BannerTextComp {...props} />
      </EditableComponent>
    </div>
  );
};
