// Styles for __name__ go here.
import styled from 'styled-components';
import { toRem } from '../../styles';

export const StyledRuleLine = styled.div<{ alignment: string }>`
  width: 100%;
  display: flex;
  justify-content: ${({ alignment }) => (alignment === 'left' ? 'start' : alignment === 'right' ? 'end' : 'center')};

  .ruler-height-1 {
    height: ${toRem(1)};
  }
  .ruler-height-3 {
    height: ${toRem(3)};
  }
  .ruler-height-5 {
    height: ${toRem(5)};
  }

  .ruler-length-20 {
    width: 20%;
  }
  .ruler-length-40 {
    width: 40%;
  }
  .ruler-length-60 {
    width: 60%;
  }
  .ruler-length-80 {
    width: 80%;
  }
  .ruler-length-100 {
    width: 100%;
  }
`;
